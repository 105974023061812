* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.slick-slide {
  padding: 0 27px;
}

.slick-slider {
  padding: 0 10px;
}

/* the parent */
.slick-list {
  padding: 0 -27px;
}

.slick-arrow.slick-next,
.slick-arrow.slick-prev {
  background-color: #000000;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 0.8;
  height: 30px;
  width: 30px;
}

.slick-arrow.slick-next:hover,
.slick-arrow.slick-prev:hover {
  background-color: black;
  opacity: 1;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}

.slick-next {
  top: 100% !important;
  right: 30% !important;
}

.slick-prev {
  top: 100% !important;
  left: 30% !important;
}

.react-slick-prev {
  position: absolute;
  top: 100%;
  cursor: pointer;
  left: 30%;
  z-index: 1000 !important;
}

.react-slick-next {
  position: absolute;
  top: 100%;
  right: 30%;
  cursor: pointer;
  z-index: 1000 !important;
}

.arrows {
  height: 20px;
  width: 20px;
  margin: 1px;
}

.slick-slide.slick-center {
  transform: scale(1.1);
}

.slick-slide {
  padding: 0 17px;
  transform: scale(0.9);
}

.carousel-dashboards.slick-slide {
  padding: 0 0px !important;
  transform: scale(0) !important;
}

.slick-slide > div {
  height: 60vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 1524px) {
  .slick-slide > div {
    height: 50vh !important;
  }
}

.slick-dots {
  bottom: -35px !important;
  width: 100vw !important;
  left: 0px !important;
}

.slick-dots > li > button:before {
  color: White !important;
  font-size: 15px !important;
}

.slick-dots li.slick-active button:before {
  color: #c170ff !important;
  font-size: 15px;
}

.box-shadow {
  box-shadow: inset 0 1px 30px -20px rgba(255, 255, 255, 0.9);
}

.video-box-shadow {
  box-shadow: 10px 10px 30px -10px #5715c7;
}

.report-dashboard {
  transition: all 0.3s ease-in-out;
}

.report-dashboard:hover {
  box-shadow: 10px 10px 30px -10px #5715c7;
}

.backgroundimg {
  background-image: url("./Assets/Background.png"), url("./Assets/glitter.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.backgroundglitter {
  background-image: url("./Assets/glitter.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

.dashboards-preview-card {
  background: linear-gradient(147.68deg, #1b0a5d 6.05%, #1c0040 95.99%);
}

.video-react.video-react-fluid,
.video-react.video-react-16-9,
.video-react.video-react-4-3 {
  width: 45vw !important;
  height: 55vh !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center !important;
}

video {
  object-fit: cover !important;
}
.ant-collapse-item {
  border: none !important;
  width: 100%;
  border-radius: 10px !important;
  padding: 20px 10px;
  background-color: #170534;
}

.ant-collapse-header-text {
  color: white !important;
  margin-left: 1%;
  font-size: 1.3rem !important;
}

.ant-collapse-content-box {
  margin-left: 1% !important;
}

.ant-collapse-content {
  background: transparent !important;
  border: none !important;
  color: white !important;
}

.ant-collapse {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 40px;
}

.ant-collapse-expand-icon {
  color: white !important;
}

.anticon {
  font-size: 40px !important;
}

.dropdown-icon {
  font-size: 20px !important;
}

.footer-bg {
  background: linear-gradient(0deg, #130839, #130839),
    linear-gradient(
      90deg,
      rgba(30, 17, 66, 0.2) 0%,
      rgba(12, 5, 39, 0.2) 50.38%,
      rgba(27, 15, 62, 0.2) 100%
    );
}

.connector-request-container {
  background: linear-gradient(
    90deg,
    #1f1244 0%,
    #0a0323 55.48%,
    #1b0f3e 90.08%
  );
}

.dropdown {
  margin: 0 auto;
  z-index: 10000;
  width: 300px;
  position: relative;
}

.dropdown,
.dropdown * {
  z-index: 10;
}

.dropdown .dropdown-btn {
  cursor: pointer;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #777;
  font-weight: 500;
}

.dropdown-content {
  position: absolute;
  top: 110px;
  left: 0;
  width: 100%;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
}

.dropdown-content .item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-content .item:hover {
  background: #000000;
}

.box-shadow-compare {
}

.custom-dropdown {
  width: 20vw;
  background-color: #18053654;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.selected-option {
  background-color: hsla(263, 83%, 12%, 0.329);
  color: white;
  border-radius: 10px;
  box-shadow: -3px 3px 3px 0px rgba(129, 129, 129, 0.4) inset,
    -1px 1px 1px 0px rgba(255, 255, 255, 0.4) inset,
    3px -3px 3px 0px rgba(255, 255, 255, 0.4) inset,
    1px -1px 1px 0px rgba(129, 129, 129, 0.4) inset;
}

.options-list {
  width: 20vw;
  border-radius: 10px;
  padding: 20px;
  flex-direction: column;
  background-color: #0b0323 !important;
  gap: 20px;
  box-shadow: -3px 3px 3px 0px rgba(129, 129, 129, 0.4) inset,
    -1px 1px 1px 0px rgba(255, 255, 255, 0.4) inset,
    3px -3px 3px 0px rgba(255, 255, 255, 0.4) inset,
    1px -1px 1px 0px rgba(129, 129, 129, 0.4) inset;
}

.purple-image-bg {
  background-image: url("./Assets/BG.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

.box-shadow-div {
  box-shadow: -3px 3px 3px 0px rgba(129, 129, 129, 0.4) inset,
    -1px 1px 1px 0px rgba(255, 255, 255, 0.4) inset,
    3px -3px 3px 0px rgba(255, 255, 255, 0.4) inset,
    1px -1px 1px 0px rgba(129, 129, 129, 0.4) inset;
}

.carousel {
  height: auto !important;
  flex-grow: 1;
}

.horizontalSlider___281Ls.carousel__slider.carousel__slider--horizontal {
  height: 300px !important;
}

.image-dashboard {
  margin-right: 10px !important;
}

.first-image-dashboard {
  margin-right: 5px !important;
  margin-left: -30px !important;
}

video {
  border-radius: 10px !important;
}

.video-react .video-react-big-play-button {
  top: 40% !important;
  left: 45% !important;
}

.video-react .video-react-big-play-button {
  width: 2em !important;
  border-radius: 50% !important;
  height: 2em !important;
  background-color: #6064c7 !important;
  border: none !important;
  box-shadow: 5px 5px 29px 0px #9360ff;
}
.video-react .video-react-big-play-button:before {
  top: 0.3em !important;
}

.dashboard-image {
  width: 400px !important;
  height: 220px !important;
}

.ant-modal-header {
  background-color: #0a0323 !important;
  color: white !important;
  font-weight: 600 !important;
}

.ant-modal-header {
  padding-left: 2%;
  padding-right: 2%;
}
.ant-modal-title {
  padding-bottom: 10px;
  color: white !important;
  background-color: #0a0323 !important;
  border-bottom: 2px solid #2f3339;
}

.logo-image {
  width: 100px !important;
  height: 100px !important;
}

.ant-modal {
  display: flex !important;
  width: 100% !important;
  justify-content: center !important;
  align-items: center !important;
  top: 50px !important;
}

.ant-modal-content {
  box-shadow: inset 0 1px 30px -20px rgba(255, 255, 255, 0.9) !important;
  width: 40vw !important;
  background-color: #0a0323 !important;
  padding: 20px 15px !important;
}

._3efP_GeH5kyBAzqnLzL.adpQ3VUeQbmczOdx9ymw.w_Mtb2W9166EgOSv9i3M._kN_bCa3VNYpqFLH311L {
  width: 50vw !important;
}

.mobile-sidenav {
  height: calc(100vh - 100px);
}

@media only screen and (max-width: 1000px) {
  .react-slick-prev {
    top: 110% !important;
    left: 5% !important;
  }
  .react-slick-next {
    top: 110% !important;
    right: 5% !important;
  }
  .slick-slide > div {
    height: 47vh !important;
  }
  .video-react.video-react-fluid,
  .video-react.video-react-16-9,
  .video-react.video-react-4-3 {
    width: 90vw !important;
    height: 35vh !important;
  }
  .calendly-inline-widget {
    min-width: 400px !important;
    width: 45vw !important;
    height: 90vh !important;
  }
  .custom-dropdown {
    width: 60vw;
    background-color: #18053654;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .options-list {
    width: 60vw;
  }
}

@media only screen and (max-width: 700) {
  .slick-slide > div {
    height: 40vh !important;
  }
  .slick-slide {
    padding: 0 25px !important;
  }
  .slick-slider {
    padding: 0 25px !important;
  }
  .slick-list {
    padding: 0 -57px;
  }
}
@media only screen and (max-width: 1000px) {
  .slick-slide > div {
    height: 40vh !important;
  }
  .slick-slide {
    padding: 0 5px !important;
  }
  .slick-slider {
    padding: 0 5px !important;
  }
}

.calendly-inline-widget {
  width: 25vw;
  height: 90vh;
}

/* Add this to your CSS file */
.custom-dots-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
  bottom: -30px; /* Adjust this value as needed */
}
