@tailwind base;
@tailwind components;
@tailwind utilities;

.mainHeading {
  @apply text-[30px] lg:text-5xl 2xl:text-[60px] leading-[38px] md:leading-[70px] lg:leading-[80px] w-11/12 lg:w-8/12 md:w-8/12 mx-auto text-center text-white font-[600];
}

.mainHead {
  @apply text-[30px] lg:text-5xl 2xl:text-[60px] leading-[38px] md:leading-[70px] lg:leading-[80px] font-[600];
}

.mainSection {
  @apply text-[16px] lg:text-[1.35rem] 2xl:text-[1.55rem];
}

.otherSectionHeading {
  @apply text-[1.5rem] lg:text-4xl;
}

.otherSection {
  @apply text-[14px] lg:text-[1.25rem] lg:leading-[30px];
}

.subSectionHeading {
  @apply text-[16px] lg:text-[1.25rem];
}

.subSection {
  @apply text-[12px] lg:text-lg;
}

.footerSections {
  @apply text-[13px] lg:text-base;
}

.subSubSectionHeading {
  @apply text-[20px] lg:text-[1.6rem];
}

.mainButton {
  @apply bg-bgBlue text-white px-10 md:px-9 py-2 lg:py-4 rounded-[100px] text-[14px] lg:text-[1.15rem];
}

.reportHeading {
  @apply text-[14px] lg:text-[20px];
}

.reportText {
  @apply text-[11px] lg:text-base;
}

.animatedButton {
  --purple: #9b7eda;
  padding: 0.8em 3em;
  letter-spacing: 0.08em;
  position: relative;
  font-family: inherit;
  border-radius: 0.6em;
  overflow: hidden;
  transition: all 0.3s;
  line-height: 1.4em;
  border: 2px solid var(--purple);
  background: linear-gradient(
    to right,
    rgba(155, 126, 218, 0.1) 1%,
    transparent 40%,
    transparent 60%,
    rgba(155, 126, 218, 0.1) 100%
  );
  color: white;
  box-shadow: inset 0 0 10px rgba(155, 126, 218, 0.4),
    0 0 9px 3px rgba(155, 126, 218, 0.1);
}

.animatedButton:hover {
  color: #bfa3f3;
  box-shadow: inset 0 0 10px rgba(155, 126, 218, 0.6),
    0 0 9px 3px rgba(155, 126, 218, 0.2);
}

.animatedButton:before {
  content: "";
  position: absolute;
  left: -4em;
  width: 4em;
  height: 100%;
  top: 0;
  transition: transform 0.4s ease-in-out;
  background: linear-gradient(
    to right,
    transparent 1%,
    rgba(155, 126, 218, 0.1) 40%,
    rgba(155, 126, 218, 0.1) 60%,
    transparent 100%
  );
}

.animatedButton:hover:before {
  transform: translateX(15em);
}

.viewDemoButton {
  --green: #fff;
  padding: 0.7em 2.7em;
  letter-spacing: 0.06em;
  position: relative;
  font-family: inherit;
  border-radius: 0.6em;
  overflow: hidden;
  transition: all 0.3s;
  line-height: 1.4em;
  border: 2px solid var(--green);
  background: linear-gradient(
    to right,
    rgba(27, 253, 156, 0.1) 1%,
    transparent 40%,
    transparent 60%,
    rgba(27, 253, 156, 0.1) 100%
  );
  color: var(--green);
  box-shadow: inset 0 0 10px rgba(155, 126, 218, 0.4),
    0 0 9px 3px rgba(27, 253, 156, 0.1);
}

.viewDemoButton:hover {
  color: #9b7eda;
  box-shadow: inset 0 0 10px rgba(155, 126, 218, 0.6),
    0 0 9px 3px rgba(155, 126, 218, 0.2);
}

.viewDemoButton:before {
  content: "";
  position: absolute;
  left: -4em;
  width: 4em;
  height: 100%;
  top: 0;
  transition: transform 0.4s ease-in-out;
  background: linear-gradient(
    to right,
    transparent 1%,
    rgba(27, 253, 156, 0.1) 40%,
    rgba(27, 253, 156, 0.1) 60%,
    transparent 100%
  );
}

.viewDemoButton:hover:before {
  transform: translateX(20em);
}

.gradient-line {
  height: 1px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  border: none;
  margin: 20px 0;
}
