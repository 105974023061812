.container {
  --circleDiameter: 100%;
  --planets: 60px;
}

.hero-rotate {
  animation: rotate 30s linear infinite;
}

.planet {
  width: var(--planets);
  animation: maintain 30s linear infinite;
}

.planet:nth-child(1) {
  top: calc(-1 * var(--planets) / 2);
  left: calc(var(--circleDiameter) / 2 - var(--planets) / 2 - 5px);
}

.planet:nth-child(2) {
  top: calc(var(--circleDiameter) / 2 - var(--planets) / 2 - 5px);
  right: calc(-1 * var(--planets) / 2);
}

.planet:nth-child(3) {
  top: calc(var(--circleDiameter) / 2 - var(--planets) / 2 - 5px);
  left: calc(-1 * var(--planets) / 2);
}

.planet:nth-child(4) {
  left: calc(var(--circleDiameter) / 2 - var(--planets) / 2 - 5px);
  bottom: calc(-1 * var(--planets) / 2);
}

.planet > img {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes maintain {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(-360deg);
  }
}
